<template>
	<div class="">
		<b-button v-b-modal.new-user-modal variant="primary"> New {{ title }} </b-button>
		<b-modal
			id="new-user-modal"
			ref="newUserModal"
			:title="isEdit ? `Edit ${title}` : `Add new ${title}`"
			ok-title="Save"
			centered
			cancel-variant="outline-secondary"
			@hidden="resetModal"
			@ok="handleOk"
		>
			<validation-observer ref="formRules">
				<b-form>
					<b-row>
						<!-- name -->
						<b-col cols="12">
							<b-form-group label="Name" label-for="name">
								<validation-provider #default="{ errors }" name="Name" rules="required">
									<b-form-input id="name" v-model="formData.name" :state="errors.length > 0 ? false : null" placeholder="Name" />
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>

						<!-- phone -->
						<b-col cols="12">
							<b-form-group label="Phone" label-for="phone">
								<validation-provider #default="{ errors }" name="Phone" rules="required">
									<b-form-input id="phone" v-model="formData.phone" :state="errors.length > 0 ? false : null" placeholder="Phone" />
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>
					</b-row>
				</b-form>
			</validation-observer>
		</b-modal>
	</div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { BFormInput, BFormGroup, BForm, BRow, BCol, BButton } from 'bootstrap-vue';
import { required } from '@validations';
import store from '@/store';

export default {
	components: {
		ValidationProvider,
		ValidationObserver,
		BFormInput,
		BFormGroup,
		BForm,
		BRow,
		BCol,
		BButton,
	},

	props: {
		title: {
			type: String,
			default: 'User',
		},

		userType: {
			type: Number,
			default: 0,
		},

		data: {
			type: Object,
			default: () => ({
				name: '',
				phone: '',
			}),
		},
	},

	data: () => ({
		required,
	}),

	computed: {
		formData() {
			return {
				...this.data,
			};
		},

		isEdit() {
			return this.data.name !== '';
		},
	},

	methods: {
		resetModal() {
			this.$emit('hidden');
		},

		handleOk(bvModalEvt) {
			// Prevent modal from closing
			bvModalEvt.preventDefault();
			// Trigger submit handler
			this.handleSubmit();
		},

		async handleSubmit() {
			const success = await this.$refs.formRules.validate();
			if (!success) return;

			if (this.isEdit) {
				await store.dispatch('users/updateUser', { ...this.formData, type: this.userType, userId: this.formData.id });
			} else {
				await store.dispatch('users/addUser', { ...this.formData, type: this.userType });
			}

			// Hide the modal manually
			this.$nextTick(() => {
				this.$refs.newUserModal.toggle();
			});

			this.$emit('done');
		},
	},
};
</script>

<style lang="scss"></style>
